<template>
    <recess-modal
        :show-modal="isModalVisible"
        @close="close"
    >
        <template slot="modal-title">
            <h3 class="qa-order-status-modal-title"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.Title }} </h3>
        </template>

        <template slot="modal-body">
            
            <div>
                <span class="d-block font-weight-bold">{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingValidation.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingValidation.Description }} </span> 
                <recess-divider show-line />
            </div>
            
            <div>
                <span class="d-block font-weight-bold">{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingAccount.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingAccount.Description }} </span> 
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.RejectedAccount.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.RejectedAccount.Description }} </span> 
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingProvider.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingProvider.Description }} </span> 
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold">{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.RejectedProvider.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.RejectedProvider.Description }} </span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.AcceptedProvider.Name }}</span> 
                <span>{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.AcceptedProvider.Description }}</span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.CancelledAccount.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.CancelledAccount.Description }} </span> 
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.CancelledAdmin.Name }}</span>
                <span>{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.CancelledAdmin.Description }} </span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingInvoice.Name }}</span> 
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingInvoice.Description }} </span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.Invoiced.Name }}</span>
                <span>{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.Invoiced.Description }}</span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold">{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.Paid.Name }}</span>
                <span>{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.Paid.Description }} </span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold">{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingCredited.Name }}</span>
                <span>{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.PendingCredited.Description }} </span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold">{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.Credited.Name }}</span>
                <span>{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.Credited.Description }} </span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.NotInvoiced.Name }}</span>
                <span>{{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.NotInvoiced.Description }}</span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.InvoicedByThirdParty.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.InvoicedByThirdParty.Description }}</span>
                <recess-divider show-line />
            </div>
            <div>
                <span class="d-block font-weight-bold"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.CancelledWithInvoice.Name }}</span>
                <span> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes.CancelledWithInvoice.Description }}</span>
                
            </div>
        </template>
    </recess-modal>
</template>

<script>
    import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

    export default {
        name: 'OrderStatusPopupComponent',
        props: {
            isModalVisible: {
                type: Boolean,
                default: false
            }   
        },
        data(){
            return {
                PROVIDER
            }
        },
        methods: {
            close() {
                this.$emit('close')
            }  
        }
    }
</script>
