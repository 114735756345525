<template>
    <div>
        <recess-table-flex 
            class="qa-table-order-list u-overflow-inherit" 
            :table-options="setTableOptions" 
            :pagination-options="setPaginationOptions" 
            @getData="updateList"
        >
            <recess-table-flex-row v-if="orderPage">
                <recess-table-flex-cell class="d-flex align-items-start justify-content-between">  

                    <div class="w-70">
                        <div v-if="isCheckedAll">
                            <p>{{ PROVIDER.Order.OrderList.ExportAllOrders.Description }}
                                <recess-button
                                    :title="`${PROVIDER.Order.OrderList.ExportAllOrders.Button} (${setPaginationOptions.totalCount})`" 
                                    variant="variant4"  
                                    class="mr-3 u-fw-semi-bold qa-select-all-orders-list-button"
                                    @click.native.prevent="downloadOrderExport(true)" 
                                />
                            </p>
                        </div>
                    </div>
					
                    <div class="d-flex justify-content-end">
                        <recess-button 
                            variant="secondary" 
                            :title="PROVIDER.Order.OrderList.ExportOrdersButton" 
                            class="qa-download-order-excel mr-3"  
                            @click.native.prevent="downloadOrderExport()"
                        />  
						
                        <recess-button
                            variant="secondary"
                            :title="BUTTON_TEXT.editInGroup"
                            :disabled="editOrdersInGroup"
                            class="qa-open-edit-orders-in-group-popup-button"
                            @click.native.prevent="editOrdersInGroupHandler()"
                        />
                    </div>
				
                </recess-table-flex-cell>
            </recess-table-flex-row>

            <recess-table-flex-head :set-fixed-header="true" class="qa-order-list-header">
                <recess-table-flex-row>
                    <recess-table-flex-head-item cell-size="narrow" v-show="!isHistoricalOverview">
                        <recess-checkbox-input
                            v-model="isCheckedAll"
                            name="select-all-orders-per-page"
                            class="qa-select-all-orders-per-page"
                            variant="secondary"
                            :disabled="disableProviderApprovalButtonsForAdmin || emptyOrderList"
                            @input="(newValue) => selectAllOrdersPerPageHandler(newValue)"
                        />
                    </recess-table-flex-head-item>
                    <recess-table-flex-head-item
                        v-for="(header, index) in headers"
                        v-show="header.hideOnHistoricalOverview !== isHistoricalOverview"
                        :key="index"
                        :cell-size="header.cellSize"
                        :sortable="header.isSortable"
                        :sort-key="header.key"
                        @emitSortableKey="emitSortBy(header.key)"
                    >
                        <p class="m-0">{{ header.title }}</p>

                        <recess-icon v-if="header.title === PROVIDER.Order.OrderList.TableHeaders.Status" class="ml-1 qa-open-order-status-modal" icon="info" color="secondary" cursor="pointer" @click="showOrderStatusModal" />
                    </recess-table-flex-head-item>
                </recess-table-flex-row>
            </recess-table-flex-head>
            <recess-table-flex-body>
                <recess-table-flex-row v-for="(order, orderIndex) in orders" :key="orderIndex">
                    <recess-table-flex-cell cell-size="narrow" v-show="!isHistoricalOverview">
                        <recess-checkbox-input
                            v-model="order.isChecked"
                            name="select-one-order-list"
                            :disabled="disableProviderApprovalButtonsForAdmin"
                            :default-checked="order.isChecked"
                            :class="[`qa-select-one-order-list-${orderIndex}`, {'qa-select-one-pa-order-list' : isPurchaseAgreement(order.orderType)}]"
                            variant="secondary"
                            @input="(newValue) => checkOneOrderHandler(order, orderIndex)"
                        />
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[0].title">
                        <router-link
                            v-if="enableOrderDetailsLink(order.status) && !isPurchaseAgreement(order.orderType)"
                            :to="{name: 'order-details', params: { id: `${order.id}`, prevPath: $route.name }}"
                            :class="`qa-order-list-order-number-${orderIndex}`"
                        >{{ order.orderNumber }}</router-link>

                        <router-link
                            v-else-if="enableOrderDetailsLink(order.status) && isPurchaseAgreement(order.orderType)"
                            :to="{name: 'project-order-details', params: { id: `${order.id}`, prevPath: $route.name }}"
                            :class="`qa-project-order-list-order-number qa-order-list-order-number-${orderIndex}`"
                        >{{ order.orderNumber }}</router-link>

                        <p v-else :class="`qa-order-list-order-number-${orderIndex} m-0`">{{ order.orderNumber }}</p>
                        <p v-if="isPurchaseAgreement(order.orderType)"
                            :class="`qa-project-order-list-order-type-${orderIndex}`"
                        >
                            {{ PROVIDER.Order.OrderList.OrderTypes.PurchaseAgreement }}
                        </p>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[1].title" :class="`qa-order-list-course-name-${orderIndex}`">{{ order.courseName }}</recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[2].title" :class="`qa-order-list-price-${orderIndex}`">{{ order.totalAmountWithoutVatWithDiscount | currency }}</recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[3].title">
                        <div :class="`qa-order-list-first-start-moment-id-${orderIndex}`">
                            {{ !isPurchaseAgreement(order.orderType) ? setStartMomentId(order) || '-' : '-' }}
                            <time :class="`d-flex mt-1 qa-order-list-first-start-moment-date-${orderIndex}`">{{ setStartMomentDate(order) | formatDate }}</time>
                        </div>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell v-show="!isHistoricalOverview" :data-label="headers[4].title" :class="`qa-order-list-student-name-${orderIndex}`">
                        {{ !isPurchaseAgreement(order.orderType) ? order.studentName || '-' : '-' }}
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[5].title" :class="`qa-order-list-account-name-${orderIndex}`">{{ order.accountName || '-' }}</recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[6].title" :class="`qa-order-list-date-created-${orderIndex}`">{{
                        order.dateCreated | formatDate
                    }}</recess-table-flex-cell>
                    <recess-table-flex-cell v-show="!isHistoricalOverview" :data-label="headers[7].title" :class="`qa-order-list-status-${orderIndex}`">{{
                        order.statusDisplayValue
                    }}</recess-table-flex-cell>
                    <!-- Accept order icon button -->
                    <recess-table-flex-cell class="d-flex flex-column">
                        <div v-if="showProviderApprovalButtons(order.status)">
                            <recess-button
                                variant="tertiary"
                                :title="BUTTON_TEXT.acceptOrder"
                                :disabled="disableProviderApprovalButtonsForAdmin"
                                :class="`w-100 mb-2 u-font-size-14 qa-orders-list-accept-button-${orderIndex}`"
                                @click.native.prevent="acceptOrderHandler(order)"
                            />
                            <recess-button 
                                variant="tertiary"
                                :title="BUTTON_TEXT.rejectOrder"
                                :disabled="disableProviderApprovalButtonsForAdmin"
                                :class="`w-100 u-font-size-14 qa-orders-list-reject-button-${orderIndex}`"
                                @click.native.prevent="rejectOrderHandler(order)"
                            />
                        </div>
                        <div v-if="order.status === 'PendingAccount'">
                            {{ PROVIDER.Order.OrderList.NoActions }}
                        </div>
                    </recess-table-flex-cell>
                </recess-table-flex-row>
            </recess-table-flex-body>
        </recess-table-flex>

        <edit-orders-in-group-pop-up-component
            v-show="isEditOrdersInGroupModalVisible"
            :provider-id="setSelectedFilters.providerId"
            :orders="selectedOrders"
            :is-modal-visible="isEditOrdersInGroupModalVisible"
            @close="closeEditOrdersInGroupModal"
            @refreshOrders="getDataOnPageChange"
        />

        <reject-order-popup-component 
            v-show="isRejectOrderModalVisible" 
            :is-modal-visible="isRejectOrderModalVisible" 
            :is-purchase-agreement-order="isPurchaseAgreement(selectedOrder?.orderType)"
            @close="closeRejectOrderModal"
            @submit="rejectOrder(selectedOrder.id)"
        />

        <accept-order-popup-component
            :is-modal-visible="isAcceptOrderModalVisible"
            :order-number="orderNumber" 
            :provider-id="setSelectedFilters.providerId"
            :startmoment-selection="selectedStartmomentData" 
            @close="closeAcceptOrderModal"
            @submit="acceptOrder(selectedOrder.id)"
        />

        <accept-purchase-agreement-popup-component
            :is-modal-visible="isAcceptPurchaseAgreementModalVisible"
            :order-number="orderNumber" 
            :provider-id="setSelectedFilters.providerId"
            @close="closeAcceptPurchaseAgreementModal"
            @submit="acceptOrder(selectedOrder.id)"
        />

        <accept-incompany-purchase-agreement-popup-component
            :is-modal-visible="isAcceptIncompanyPurchaseAgreementModalVisible"
            :order-number="orderNumber" 
            :provider-id="providerId"
			:account-id="accountId"
            @close="closeAcceptIncompanyPurchaseAgreementModal"
            @submit="acceptOrder(order.id)"
        />

        <order-status-popup-component 
            :is-modal-visible="isOrderStatusModalVisible" 
            @close="closeOrderStatusModal"
        /> 
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions } from 'vuex'
import { getItemsFromSearchIndex, postItem, getItemById } from '@/../../shared/api/SharedClient'
import { PAGINATION, BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import acceptRejectOrderMixin from '@/mixins/acceptRejectOrderMixin'
import { toISODateString, formatUtcDateTimeToLocalDate } from '@/utils/dateTimeHelper'
import { saveAs } from 'file-saver'
import { sortBy } from '@/../../shared/utils/sorting'
import UserService from '../../../../../shared/services/UserService' 
import { minimumSelectionExportError } from '../../../../../shared/constants/validationMessageHelper'
 

import OrderStatusPopupComponent from '../../../components/molecules/OrderStatusPopupComponent.vue'
import EditOrdersInGroupPopUpComponent from '../../../components/molecules/EditOrdersInGroupPopUpComponent.vue'
 
import tableMixin from '@/../../shared/mixins/tableMixin' 

export default {
	name: 'OrderList',
	components: {
		OrderStatusPopupComponent,
		EditOrdersInGroupPopUpComponent
	},
	mixins: [tableMixin, acceptRejectOrderMixin],
	props: {
		setSelectedFilters: {
			type: Object,
			default: () => {
				return {}
			}
		},
		isOrderOverview: {
			type: Boolean
		}
	},
	data() {
		const paginationOptions = [
			{ displayText: '5', value: 5 },
			{ displayText: '10', value: 10 },
			{ displayText: '25', value: 25 },
			{ displayText: '50', value: 50 },
			{ displayText: '100', value: 100 },
			{ displayText: '250', value: 250 }
		]

		return {
			PROVIDER,
			userService: new UserService(),
			isAdministrator: false,
			PAGINATION,
			BUTTON_TEXT,
			orders: [],
			selectedOrders: [],
			isCheckedAll: false,
			isEditOrdersInGroupModalVisible: false,
			selectedOrder: null,
			selectedOrderStatus: null,
			isHistoricalOverview: this.$route.path === '/result/resultaten',
			headers: [
				{
					key: 'orderNumber',
					title: PROVIDER.Order.OrderList.TableHeaders.Order,
					isSortable: true
				},
				{
					key: 'courseName',
					title: PROVIDER.Order.OrderList.TableHeaders.Title,
					isSortable: true
				},
				{
					key: 'totalPrice',
					title: PROVIDER.Order.OrderList.TableHeaders.Price,
					isSortable: true
				},
				{
					key: 'firstChoiceStartMomentId',
					title: PROVIDER.Order.OrderList.TableHeaders.Startmoment,
					isSortable: true
				},
				{
					key: 'studentName',
					title: PROVIDER.Order.OrderList.TableHeaders.Student,
					hideOnHistoricalOverview: true,
					isSortable: true
				},
				{
					key: 'accountName',
					title: PROVIDER.Order.OrderList.TableHeaders.Account,
					isSortable: true
				},
				{
					key: 'dateCreated',
					title: PROVIDER.Order.OrderList.TableHeaders.DateCreated,
					isSortable: true
				},
				{
					key: 'status',
					title: PROVIDER.Order.OrderList.TableHeaders.Status,
					isSortable: true,
					hideOnHistoricalOverview: true
				},
				{
					key: 'actions',
					title: PROVIDER.Order.OrderList.TableHeaders.Actions
				}
			],
			filter: {
				search: '',
				searchMode: 'all',
				top: 0,
				skip: 0,
				orderBy: ['dateCreated desc'],
				filter: ''
			},
			sortOption: {
				sortKey: null,
				sortDirection: null
			},
			isOrderStatusModalVisible: false,
			selectedStartmomentData: null,
			orderNumber: '',
			accountId: '', 
			// TABLE AND PAGINATION OPTIONS - REQUIRED
			setTableOptions: {
				title: PROVIDER.Order.OrderList.TableTitle,
				setCellSizes: true
			},
			setPaginationOptions: {
				showTopPagination: true,
				showBottomPagination: true,
				currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
				totalCount: 0,
				selectOptions: paginationOptions,
				defaultTotalItemsPerPage: paginationOptions[3].value,
				itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[3].value,
				showSelectOption: true,
				selectOptionsLabel: PAGINATION.selectOptionsLabel,
				paginationShowLabel: PAGINATION.paginationShowLabel,
				paginationOfLabel: PAGINATION.paginationOfLabel
			},
			courseStartmomentsOptions: []
		}
	},
	computed: {
		showProviderButtonsColumn() {
			return this.$route.path === '/order/overview' && PROVIDER.Order.OrderList.ShowProviderActionButtons.includes(this.selectedOrderStatus)
		},
		orderPage() {
			return this.$route.path === '/order/overview'
		},
		editOrdersInGroup() { 
			return this.selectedOrders?.length === 1 || this.emptySelectedOrders || this.disableProviderApprovalButtonsForAdmin || this.setSelectedFilters.orderStatus !== PROVIDER.Order.Shared.OrderStatus.PendingProvider || this.showEditOrderInGroupBtn()
		},
		isAllOrdersChecked() { 
			return this.orders.length === this.selectedOrders.length && !this.emptySelectedOrders
		},
		emptyOrderList() {
			return this.orders.length === 0
		},
		emptySelectedOrders() {
			return this.selectedOrders.length === 0
		}
	},
	watch: {
	 	isAllOrdersChecked: {
	 		handler() {
	 			this.setIsCheckedAllStatus()
	 		}
	 	}
	},
	async created() {
		this.isAdministrator = await this.userService.isAdministrator()
	},
	mounted() {
		// Code that will run only after the
		// entire view has been rendered
		// We need this otherwise the token will not be sent in the request header through axios
		this.$nextTick(() => {
			this.$bus.on('validate-patch-order-lines', this.validatePatchOrderLines)
			this.$bus.on('refresh-orders', this.getDataOnPageChange)

		})
	},
	beforeDestroy() {
		this.$bus.off('update-order-status-transition')
		this.$bus.off('validate-patch-order-lines')
		this.$bus.off('refresh-orders')
	},
	methods: {
		...mapActions('orderModule', ['getOrder']),
		enableOrderDetailsLink(orderStatus) {
			return orderStatus !== 'PendingAccount'
		},
		showEditOrderInGroupBtn(){
			if(this.selectedOrder){
				const isPAOrder = this.isPurchaseAgreement(this.selectedOrder.orderType)
				return isPAOrder
			}
			if(this.selectedOrders){
				const anySelectedOrderIsPA = this.selectedOrders.some((order) => this.isPurchaseAgreement(order.orderType))
				return anySelectedOrderIsPA
			}

			return true
		},
		showOrderStatusModal() {
			this.isOrderStatusModalVisible = true
		},
		closeOrderStatusModal() {
			this.isOrderStatusModalVisible = false
		},
		emitSortBy(headerKey) {
			this.sortOption = sortBy({ sortOption: this.sortOption, key: headerKey })

			this.updateList({ currentPage: 1, itemsPerPage: this.setPaginationOptions.itemsPerPageFromUrlQuery })
		},
		getSelectedSort() {
			if (!this.sortOption.sortKey) return
			this.filter.orderBy = [`${this.sortOption.sortKey} ${this.sortOption.sortDirection}`]
		},
		updateList({ currentPage, itemsPerPage }) {
			this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
			this.setPaginationOptions.currentPage = currentPage

			this.filter.top = itemsPerPage
			this.filter.skip = currentPage * itemsPerPage - itemsPerPage

			this.filter.filter = ''
			this.getSelectedSort()
			
			this.getOrderStatus()
			this.getSelectedSearch()
			this.getSelectedProvider()
			this.getSelectedDatesRange()

			// Get order type if it's on the order overview page
			if(this.isOrderOverview) this.getOrderType()

			// Get data from api
			this.getDataOnPageChange()
		},
		getSelectedSearch() {
			this.filter.search = this.setSelectedFilters.searchQuery
		},
		concatenateFilterString() {
			if (this.filter.filter.length > 0) {
				this.filter.filter += ` and `
			}
		},
		getOrderType() {
			if(this.setSelectedFilters.orderType !== "All") {
				this.concatenateFilterString()
				this.filter.filter += `orderType eq '${this.setSelectedFilters.orderType}'`
			}		
		},
		getOrderStatus() {
			if (!this.isAdministrator && (!this.setSelectedFilters || !this.setSelectedFilters.orderStatus || this.setSelectedFilters.orderStatus === 'All')) {
				this.filter.filter = `status ne 'Invalid'`
			}

			if (!(this.setSelectedFilters && this.setSelectedFilters.orderStatus && this.setSelectedFilters.orderStatus !== 'All')) return

			this.concatenateFilterString()
			this.filter.filter += `status eq '${this.setSelectedFilters.orderStatus}'`
		},
		getSelectedProvider() {
			if (!(this.setSelectedFilters && this.setSelectedFilters.providerId)) return

			this.concatenateFilterString()
			this.filter.filter += `providerId eq '${this.setSelectedFilters.providerId}'`
		},
        getSelectedOrderId() {
            if (this.selectedOrders.length === 0) return
 
			let selectedIds = ''
			this.selectedOrders.forEach((order) => {
				if(selectedIds.length > 1) {
					selectedIds += ` or `
				}
				selectedIds += `id eq '${order.id}'`
			})

			return selectedIds
        },
		getSelectedDatesRange() {
			if (!(this.setSelectedFilters && this.setSelectedFilters.currentMonth && this.setSelectedFilters.currentYear)) return
			const currentMonthYear = dayjs(`${this.setSelectedFilters.currentMonth}-${this.setSelectedFilters.currentYear}`, 'M-YYYY')
			const currentStartDateISO = toISODateString(currentMonthYear.startOf('M'))
			const currentEndDateISO = toISODateString(currentMonthYear.endOf('M'))

			this.concatenateFilterString()
			this.filter.filter += `(dateCreated gt ${currentStartDateISO}) and (dateCreated lt ${currentEndDateISO})`
		},
		getDataOnPageChange() {
			this.resetSelectAllOrders()
			this.getOrders() 
			this.setCurrentPageAsQueryParam()
		},
		async getOrders() {
			try {
				const response = await getItemsFromSearchIndex(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, API_CALL_URL_PATHS.search, this.filter)
				if (!response) return

				this.setInitialCheckboxState(response.results)

				this.setPaginationOptions.totalCount = response.count
			} catch (error) {
				console.error('Something went wrong while retrieving orders', error)
			}
		},
		setStartMomentId(order) {
			if (order.learningMethod === PROVIDER.Order.Shared.LearningMethodName.ELearning) {
				return PROVIDER.Order.OrderList.Nvt
			}

			return order.acceptedStartMomentId || order.firstChoiceStartMomentId
		},
		setStartMomentDate(order) {
			if (order.acceptedStartMomentId && order.acceptedStartMomentId === order.secondChoiceStartMomentId) {
				return order.secondChoiceStartMomentDate
			}
			return order.firstChoiceStartMomentDate
		},

		async getCourse(courseId) {  
            try {
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.courses,
                    courseId,
                    null,
                    false
                )
                if (!response) return

				const courseStartmoments = response.learningMethod.planning.startMoments.reduce((acc, startMoment) => {
                    if (!startMoment.inactive) { 
						const fullDate = {
							displayText: `${formatUtcDateTimeToLocalDate(startMoment.startDate.fullDate)} - ${startMoment.city}`,
							value: startMoment.externalId
						}
 
						return [...acc, fullDate]
                    }
                    return acc
                }, []) 

				this.courseStartmomentsOptions = courseStartmoments 
 
               
            } catch (error) {
                console.error('Something went wrong while retrieving course details', error)
            }
        },
		async acceptOrderHandler(order) { 
			if (!order.id) return 

			await this.getOrder(order.id)

			
			if(!this.isPurchaseAgreement(order.orderType)) await this.getCourse(order.courseId) 
			
			this.selectedOrder = order
			this.orderNumber = order.orderNumber
			
			if(!this.isPurchaseAgreement(order.orderType)) {
				this.selectedStartmomentData = {
					orderFirstStartmomentDate: order.firstChoiceStartMomentDate,
					orderFirstStartmomentId: order.firstChoiceStartMomentId,
					orderFirstChoiceStartMomentLocation: order.firstChoiceStartMomentLocation,
					orderSecondStartmomentDate: order.secondChoiceStartMomentDate,
					orderSecondStartmomentId: order.secondChoiceStartMomentId,
					orderSecondChoiceStartMomentLocation: order.secondChoiceStartMomentLocation,
					orderCourseId: order.courseId,
					courseStartmomentsOptions: this.courseStartmomentsOptions
				}
				this.showAcceptOrderModal()
			} else {
				
				const fetchedOrder = this.$store.state.orderModule.order
				this.accountId = fetchedOrder.accountReference.id
				const isIncompanyPurchaseAgreement = fetchedOrder.purchaseAgreementType === 'IncompanyWithoutStartmoments' || fetchedOrder.purchaseAgreementType === 'IncompanyWithStartmoments'
				
				isIncompanyPurchaseAgreement ? this.showAcceptIncompanyPurchaseAgreementModal() :this.showAcceptPurchaseAgreementModal()
			}
		},
		rejectOrderHandler(order) {
			if (!order.id) return
			this.selectedOrder = order
			this.showRejectOrderModal()
		},
		async downloadOrderExport(triggerExportAll) { 
			let exportFilterToApply  
			exportFilterToApply = JSON.parse(JSON.stringify(this.filter))

			const defaultFilter = `status eq 'PendingProvider' and providerId eq '${this.setSelectedFilters.providerId}'` 

			if(triggerExportAll && triggerExportAll !== undefined) { 
            	exportFilterToApply.top = -1
			}   
			
			else { 
				exportFilterToApply.filter = this.getSelectedOrderId() 
			}	 
			
			
			if(triggerExportAll === undefined && (exportFilterToApply.filter === undefined || exportFilterToApply.filter === defaultFilter)) {
				const toastNotification = {
					type: 'error',
					message: minimumSelectionExportError('order')
					 
				}

				this.$store.dispatch('toastNotificationModule/add', toastNotification, {
					root: true
				})

				return
			} 

			const response = await postItem(
				process.env.VUE_APP_ORDER_API_URL, 
				API_CALL_URL_PATHS.downloadOrder, 
				exportFilterToApply, 
				false, 
				'arraybuffer'
			)

			try {
				if (!response) return

				const blob = new Blob([response], { type: PROVIDER.Order.OrderList.ExportFile.MediaType })
				const currentDate = new Date().toJSON().slice(0, 10)
				const filename = `${PROVIDER.Order.OrderList.ExportFile.Name}-${currentDate}.xlsx`
				saveAs(blob, filename)
			} catch (error) {
				console.error('Something went wrong while downloading the orders export', error)
			}
		},

		// EDIT ORDERS
		resetSelectAllOrders() {
			this.selectedOrders = []
			this.isCheckedAll = false
		},
		setInitialCheckboxState(results) {
			this.orders = [] // reset orders for filtering again
			results.map((result) => {
				const orderItem = result.document
				// set isChecked status every time when order list is refreshed
				this.setIsCheckedStatus(orderItem)
				this.orders.push(orderItem)
				// set selectAll button status every time when order list is refreshed
				this.setIsCheckedAllStatus()
				return null
			})
		},
		setIsCheckedStatus(orderItem) {
			const isOrderChecked = this.selectedOrders.find((selectedOrder) => selectedOrder.orderId === orderItem.id)
			
			if (isOrderChecked) {
				orderItem.isChecked = true
			} else {
				orderItem.isChecked = false
			}
		},
		setIsCheckedAllStatus() {
			if (this.isAllOrdersChecked) {
				this.isCheckedAll = true
			} else {
				this.isCheckedAll = false
			}
		},
		editOrdersInGroupHandler() {
			this.isEditOrdersInGroupModalVisible = true
		},
		closeEditOrdersInGroupModal() {
			this.isEditOrdersInGroupModalVisible = false
			this.setIsCheckedAllStatus()
		},
		selectAllOrdersPerPageHandler() {
			this.orders.map((order) => {

				// if(this.isPurchaseAgreement(order.orderType)) {
				// 	order.isChecked = false
				// 	return
				// }
				// only add orders that are not selected yet to 'selectedOrders' array
				if (this.isCheckedAll && !order.isChecked && !this.disableProviderApprovalButtonsForAdmin) {
					order.isChecked = true
					

					const orderDetails = {
						id: order.id,
						orderType: order.orderType,
						firstChoiceStartMomentDate: order.firstChoiceStartMomentDate,
						firstChoiceStartMomentId: order.firstChoiceStartMomentId,
						firstChoiceStartMomentLocation: order.firstChoiceStartMomentLocation,
						secondChoiceStartMomentDate: order.secondChoiceStartMomentDate,
						secondChoiceStartMomentId: order.secondChoiceStartMomentId,
						secondChoiceStartMomentLocation: order.secondChoiceStartMomentLocation
					}
					this.selectedOrders.push(orderDetails)
				}

				if (!this.isCheckedAll) {
					this.selectedOrders = this.selectedOrders.filter((selectedOrder) => selectedOrder.id !== order.id)
					order.isChecked = false
				}
				return null
			})
		},
		checkOneOrderHandler(order, index) {
			if (this.orders[index].isChecked) {
				const orderDetails = {
					id: order.id,
					orderType: order.orderType,
					firstChoiceStartMomentDate: order.firstChoiceStartMomentDate,
					firstChoiceStartMomentId: order.firstChoiceStartMomentId,
					firstChoiceStartMomentLocation: order.firstChoiceStartMomentLocation,
					secondChoiceStartMomentDate: order.secondChoiceStartMomentDate,
					secondChoiceStartMomentId: order.secondChoiceStartMomentId,
					secondChoiceStartMomentLocation: order.secondChoiceStartMomentLocation
				}
				this.selectedOrders.push(orderDetails)
			} else {
				this.selectedOrders = this.selectedOrders.filter((selectedOrder) => selectedOrder.id !== order.id)
				this.isCheckedAll = false
			}
		} 
	}
}
</script>
